<template>
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               v-if="isSocialMediaBonus"
               id="authentication-modal"
               tabindex="-1"
               aria-hidden="true"
               class="overflow-y-auto overflow-x-hidden fixed z-80 w-full inset-0 max-h-full transform ease-in-out transition-all duration-300 self-center w-full m-auto inset-0 flex justify-center items-center"
          >
               <div
                    class="relative px-3 py-3 sm:py-4 sm:px-6 w-full max-w-md max-h-full tracking-wider m-auto"
               >
                    <!-- Modal content -->
                    <div class="relative bg-white rounded-lg shadow">
                         <div
                              class="corner-dots-modal absolute top-2 left-2 flex justify-center items-center"
                         >
                              <div class="slash-n-dots"></div>
                         </div>
                         <div
                              class="corner-dots-modal absolute top-2 right-2 hidden md:flex justify-center items-center"
                         >
                              <div class="slash-n-dots !rotate-64"></div>
                         </div>
                         <button
                              @click="closeModal()"
                              type="button"
                              class="close-btn-c absolute end-2.5 rounded-lg text-sm ms-auto inline-flex justify-center items-center"
                              data-modal-hide="authentication-modal"
                              style="
                                   right: -50px;
                                   background: red;
                                   border-radius: 100px;
                                   color: #fff;
                              "
                         >
                              <svg
                                   class="w-4 h-4"
                                   aria-hidden="true"
                                   xmlns="http://www.w3.org/2000/svg"
                                   fill="none"
                                   viewBox="0 0 14 14"
                              >
                                   <path
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                   />
                              </svg>
                              <span class="sr-only">Close modal</span>
                         </button>
                         <!-- Modal header -->
                         <div
                              class="flex items-center justify-center divide-gray-70 divide-x py-6 mb-2"
                         >
                              <div class="px-2 sm:px-4">
                                   <img
                                        class="w-36"
                                        :src="
                                             baseUrl.img +
                                             '/assets/img/modal-logo.svg'
                                        "
                                        alt=""
                                   />
                              </div>
                              <div class="px-2 sm:px-4">
                                   <h3
                                        class="text-sm xs:text-xl font-semibold text-gray-900 uppercase"
                                   >
                                        Social media bonus
                                   </h3>
                              </div>
                         </div>
                         <!-- Modal body -->
                         <div class="px-6">
                              <div>
                                   <img
                                        class="w-full"
                                        :src="
                                             baseUrl.img +
                                             '/assets/img/dummy.png'
                                        "
                                        alt=""
                                   />
                              </div>
                              <div
                                   class="py-5 leading-tight font-inter text-xs"
                              >
                                   Lorem ipsum dolor sit amet, consectetur
                                   adipiscing elit, sed do eiusmod tempor
                                   incididunt ut labore et dolore magna aliqua
                              </div>
                              <div class="pb-6 text-center">
                                   <button
                                        class="w-full text-white bg-primal-red hover:text-primal-yellow focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-md px-5 py-2 text-center uppercase tracking-wider"
                                   >
                                        Claim Bonus
                                   </button>
                              </div>
                         </div>
                         <div
                              class="corner-dots-modal absolute bottom-2 left-2 flex justify-center items-center"
                         >
                              <div class="slash-n-dots !rotate-12"></div>
                         </div>
                         <div
                              class="corner-dots-modal absolute bottom-2 right-2 flex justify-center items-center"
                         >
                              <div class="slash-n-dots !rotate-120"></div>
                         </div>
                    </div>
               </div>
          </div>
     </transition>
     <div
          v-if="isSocialMediaBonus"
          class="bg-gray-60 fixed inset-0 z-40"
     ></div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import eventBus from "plugins/event.js";
import { useRouter } from "vue-router";
import { utilitiesStore } from "store/utilities.js";
import { baseUrl } from "config/services.js";

export default {
     setup() {
          const router = useRouter();
          const isSocialMediaBonus = ref(false);
          const useUtilitiesStore = utilitiesStore();

          const toggleSocialMediaBonus = () => {
               isSocialMediaBonus.value = !isSocialMediaBonus.value;
          };

          const closeModal = () => {
               isSocialMediaBonus.value = false;
               useUtilitiesStore.enableScroll();
               router.replace({});
          };

          onMounted(async () => {
               eventBus.on("open:socialmediabonus", () => {
                    useUtilitiesStore.disableScroll();
                    toggleSocialMediaBonus();
               });
          });

          onUnmounted(() => {
               eventBus.off("open:socialmediabonus");
          });

          return {
               isSocialMediaBonus,
               closeModal,
               baseUrl
          };
     }
};
</script>
