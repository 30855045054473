<template>
     <!-- Main modal -->
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               v-if="isOpenFreeTopup"
               id="authentication-modal"
               tabindex="-1"
               aria-hidden="true"
               class="overflow-y-hidden overflow-x-hidden fixed z-80 w-full inset-0 max-h-full transform ease-in-out transition-all duration-300 self-center m-auto flex justify-center items-center"
          >
               <div
                    class="relative px-3 py-3 sm:py-4 sm:px-6 w-full max-w-2xl max-h-full tracking-wider m-auto"
               >
                    <div class="relative shadow">
                         <button
                              @click="closeModal()"
                              type="button"
                              class="close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
                              data-modal-hide="authentication-modal"
                         >
                              <img
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/exclude.svg'
                                   "
                                   alt=""
                              />
                              <span class="sr-only">Close modal</span>
                         </button>
                         <div
                              class="success-payment rounded-xl flex flex-col w-full mt-10 sm:mt-12 md:mt-18 lg:mt-18"
                         >
                              <div
                                   class="px-5 md:px-7 lg:px-10 py-10 flex flex-col justify-center items-center"
                              >
                                   <h3
                                        class="text-white font-helvetica font-thin tracking-wider text-center text-2xl xs:text-3xl sm:text-5xl md:text-6xl lg:text-4xl xl:text-5xl 2xl:text-4xl leading-xl xs:leading-3xl sm:leading-5xl md:leading-5xl lg:leading-4xl xl:leading-5xl 2xl:leading-4xl uppercase"
                                   >
                                        Free Gold Top Up
                                   </h3>
                                   <p
                                        class="text-white font-helveticaLight tracking-wide mt-5 md:mt-8 text-center font-normal text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl"
                                   >
                                        Unlimited fun times on Moozi! Here is
                                        <span class="font-bold text-secondary-3"
                                             >5 GC</span
                                        >
                                        for your enjoyment!
                                   </p>
                                   <img
                                        :src="
                                             baseUrl.img +
                                             '/assets/img/cow-with-logo.png'
                                        "
                                        alt="cow"
                                        class="mt-8 md:mt-16 mb-8"
                                   />
                              </div>
                         </div>
                    </div>
                    <!-- Modal content -->
               </div>
          </div>
     </transition>
     <div
          v-if="isOpenFreeTopup"
          class="fixed inset-0 z-60"
          style="background: rgba(0, 0, 0, 0.75)"
     ></div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import eventBus from "plugins/event.js";
import { utilitiesStore } from "store/utilities.js";
import { baseUrl } from "config/services.js";

export default {
     props: ["onSubmit", "setLoading"],
     setup() {
          const useUtilitiesStore = utilitiesStore();
          const isOpenFreeTopup = ref(false);

          const closeModal = () => {
               isOpenFreeTopup.value = false;
               useUtilitiesStore.enableScroll();
          };

          onMounted(async () => {
               eventBus.on("open:free_gc_top", () => {
                    useUtilitiesStore.disableScroll();
                    isOpenFreeTopup.value = true;
               });

               eventBus.on("close:free_gc_top", () => {
                    useUtilitiesStore.enableScroll();
                    isOpenFreeTopup.value = false;
               });
          });

          onUnmounted(() => {
               eventBus.off("open:free_gc_top");
               eventBus.off("close:free_gc_top");
          });

          return {
               isOpenFreeTopup,
               closeModal,
               baseUrl
          };
     }
};
</script>
