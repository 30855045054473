import Cookies from "js-cookie";
export const useAffiliate = () => {
     const setTokenParams = () => {
          const urlParams = new URLSearchParams(window.location.search);
          // if found token params
          const token = urlParams.get("token");
          if (token) {
               // set affiliate_token and expire 3days
               Cookies.set("affiliate_token", token, { expires: 3 });
          }
     };
     return {
          setTokenParams
     };
};
