<template>
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               v-if="isOpenXmas"
               id="authentication-modal"
               tabindex="-1"
               aria-hidden="true"
               class="overflow-y-auto overflow-x-hidden fixed z-80 max-h-full transform ease-in-out transition-all duration-300 self-center w-full m-auto inset-0 flex justify-center items-center"
          >
               <div
                    class="relative px-6 py-3 sm:py-4 sm:px-10 w-full max-w-lg max-h-full m-auto text-center"
               >
                    <!-- Modal content -->
                    <div class="relative rounded-xl shadow-xl">
                         <button
                              @click="closeModal()"
                              type="button"
                              class="close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center z-10"
                              data-modal-hide="authentication-modal"
                         >
                              <img
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/exclude.svg'
                                   "
                                   alt=""
                              />
                              <span class="sr-only">Close modal</span>
                         </button>
                         <div class="text-white"></div>
                         <div class="relative w-full flex justify-center">
                              <img
                                   class="w-full h-full"
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/xmas/count-down.png'
                                   "
                                   alt=""
                              />
                              <p
                                   class="font-pattaya absolute z-10 xmas-count-down h-1/2 flex justify-center items-end"
                                   :class="
                                        dailyBonus.last_claim == null ||
                                        !(
                                             new Date(
                                                  dailyBonus.next_claim_start
                                             ).getTime() -
                                                  new Date(
                                                       dailyBonus.now
                                                  ).getTime() >
                                             0
                                        )
                                             ? 'text-white'
                                             : 'text-gray-300'
                                   "
                                   style="
                                        text-shadow: 0px 12px 0px
                                             rgba(0, 0, 0, 0.5);
                                   "
                              >
                                   {{ format(new Date(dailyBonus.now), "do") }}
                              </p>
                              <span
                                   v-if="
                                        !(
                                             dailyBonus.last_claim == null ||
                                             !(
                                                  new Date(
                                                       dailyBonus.next_claim_start
                                                  ).getTime() -
                                                       new Date(
                                                            dailyBonus.now
                                                       ).getTime() >
                                                  0
                                             )
                                        )
                                   "
                                   class="absolute z-10 h-1/2 flex justify-center items-end"
                              >
                                   <svg
                                        class="xmas-count-check"
                                        viewBox="0 0 104 120"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                   >
                                        <path
                                             d="M40.1818 119.947C35.297 111.109 31.5152 104.586 28.8889 100.43L24.6869 93.8536L21.5354 89.0136C14.6545 78.2815 7.45859 69.3906 0 62.3937C4.25455 58.8689 8.24647 57.0802 11.9232 57.0802C16.4404 57.0802 20.4849 58.7637 24.004 62.078C27.5232 65.3924 31.9879 71.7054 37.3455 81.0697C43.4384 61.1837 51.2121 42.3498 60.6141 24.5682C65.8141 14.8882 70.4889 8.36475 74.5333 4.99781C78.6303 1.63086 84.0404 0 90.7636 0C94.3354 0 98.7475 0.578694 104 1.68347C90.501 12.5734 79.6808 25.5677 71.5919 40.5612C63.503 55.5546 52.998 82.0693 40.1818 120V119.947Z"
                                             fill="#19CC3E"
                                        />
                                   </svg>
                              </span>
                         </div>
                         <img
                              @click="onClaim"
                              :class="
                                   dailyBonus.last_claim == null ||
                                   !(
                                        new Date(
                                             dailyBonus.next_claim_start
                                        ).getTime() -
                                             new Date(
                                                  dailyBonus.now
                                             ).getTime() >
                                        0
                                   )
                                        ? 'cursor-pointer hover:scale-105'
                                        : 'grayscale cursor-not-allowed'
                              "
                              class="w-full h-full hidden lg:block scale-100 transition-transform duration-300 ease-in-out"
                              :src="
                                   baseUrl.img +
                                   '/assets/img/xmas/claim-desktop.png'
                              "
                              alt=""
                         />
                         <img
                              @click="onClaim"
                              :class="
                                   dailyBonus.last_claim == null ||
                                   !(
                                        new Date(
                                             dailyBonus.next_claim_start
                                        ).getTime() -
                                             new Date(
                                                  dailyBonus.now
                                             ).getTime() >
                                        0
                                   )
                                        ? 'cursor-pointer hover:scale-105'
                                        : 'grayscale cursor-not-allowed'
                              "
                              class="w-full h-full lg:hidden cursor-pointer scale-100 transition-transform duration-300 ease-in-out"
                              :src="
                                   baseUrl.img +
                                   '/assets/img/xmas/claim-mobile.png'
                              "
                              alt=""
                         />
                    </div>
               </div>
          </div>
     </transition>
     <div
          v-if="isOpenXmas"
          class="fixed inset-0 z-60"
          style="background-color: rgba(0, 0, 0, 0.6)"
     ></div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed } from "vue";
import eventBus from "plugins/event.js";
import { utilitiesStore } from "store/utilities.js";
import { baseUrl } from "config/services.js";
import { authStore } from "store/auth.js";
import { bonusStore } from "store/bonus.js";
import { differenceInDays, format } from "date-fns";
import { claimDaily as claimDailyAPI } from "api/bonus.js";

export default {
     setup() {
          const isOpenXmas = ref(false);
          const useUtilitiesStore = utilitiesStore();
          const useAuthStore = authStore();
          const useBonusStore = bonusStore();

          const closeModal = () => {
               isOpenXmas.value = false;
               useUtilitiesStore.enableScroll();
          };

          const token = computed(() => {
               return useAuthStore.token;
          });

          const dailyBonus = computed(() => {
               return useBonusStore.dailyBonus?.["daily-christmas"] || {};
          });

          const details = computed(() => {
               return useBonusStore.details["daily-christmas"];
          });

          const claimableDaily = computed(() => {
               return useBonusStore.claimable_daily["daily-christmas"];
          });

          async function onClaim() {
               await claimDailyAPI("daily-christmas").then(async (res) => {
                    await useBonusStore.getDaily("daily-christmas");
                    useBonusStore.setClaimableDaily(false, "daily-christmas");
                    setTimeout(() => {
                         // claimLoading.value = false;
                         // claimStatus.value = {
                         //      label: "Daily Reward Succesfully Claimed",
                         //      status: res.status
                         // };
                         if (res?.status) {
                              // loadParticles(configs);
                              useAuthStore.getUser(true);
                              reloadBalance();
                         }
                         // setTimeout(() => {
                         //      // claimLoading.value = true;
                         //      // const count = setInterval(() => {
                         //      //      claimCount.value = claimCount.value + 1;
                         //      // }, 1000);
                         //      setTimeout(() => {
                         //           // clearInterval(count);
                         //           claimLoading.value = false;
                         //           claimStatus.value = {
                         //                label: "CLAIMED",
                         //                status: false
                         //           };
                         //      }, 3000);
                         // }, 5000);
                    }, 2000);
               });
          }

          onMounted(async () => {
               eventBus.on("open:xmas_modal", () => {
                    useUtilitiesStore.disableScroll();
                    isOpenXmas.value = true;
               });

               if (token.value) {
                    await useBonusStore.getDaily("daily-christmas");
               }
          });

          onUnmounted(() => {
               eventBus.off("open:xmas_modal");
          });

          return {
               isOpenXmas,
               closeModal,
               baseUrl,
               dailyBonus,
               details,
               claimableDaily,
               differenceInDays,
               format,
               onClaim
          };
     }
};
</script>
