<script setup>
import Default from "./modules/default/index.vue";
import Basic from "./modules/auth/index.vue";
import { markRaw, ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useAffiliate } from "composables/use-affiliate.js";
import { useUtm } from "composables/use-utm.js";

const layout = ref();
const route = useRoute();
const { setTokenParams } = useAffiliate();
const { setUtmParams } = useUtm();

watch(
     () => route.meta?.layout,
     async (metaLayout) => {
          try {
               if (metaLayout == "basic") {
                    layout.value = markRaw(Basic);
               } else if (metaLayout != null) {
                    const component =
                         metaLayout &&
                         (await import(
                              /* @vite-ignore */ `./modules/${metaLayout}/index.vue`
                         ));

                    layout.value = markRaw(component?.default || Default);
               }
          } catch {
               if (metaLayout == "basic") {
                    layout.value = markRaw(Basic);
               } else {
                    layout.value = markRaw(Default);
               }
          }
     },
     { immediate: true }
);

onMounted(() => {
     setTokenParams();
     setUtmParams();
});
</script>

<template>
     <component :is="layout"></component>
</template>
