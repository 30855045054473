<template>
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               v-if="isDailyBonus"
               id="authentication-modal"
               tabindex="-1"
               aria-hidden="true"
               @click="handleBackdropClick"
               class="overflow-y-auto overflow-x-hidden fixed z-80 max-h-full transform ease-in-out transition-all duration-300 self-center w-full m-auto inset-0 flex justify-center items-center"
          >
               <div
                    class="relative px-3 py-3 sm:py-4 sm:px-6 lg:max-w-7xl xl:max-w-7xl 2xl:max-w-7xl tracking-wider m-auto"
                    @click.stop=""
               >
                    <div
                         class="relative daily-bonus-modal-bg py-9 md:py-7 lg:py-0 rounded-lg h-full sm:h-fit overflow-hidden lg:overflow-visible"
                    >
                         <button
                              @click="closeModal()"
                              type="button"
                              class="close-btn-c absolute top-2.5 end-2.5 right-17 xs:right-6 sm:right-2 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
                              data-modal-hide="authentication-modal"
                         >
                              <img
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/exclude.svg'
                                   "
                                   alt=""
                              />
                              <span class="sr-only">Close modal</span>
                         </button>
                         <!-- Modal header -->
                         <div
                              class="flex flex-col items-center justify-center w-full px-5 md:px-7 lg:px-0"
                         >
                              <h1
                                   class="w-full text-white font-helvetica tracking-wider text-center text-6xl xs:text-6xl sm:text-7xl md:text-7xl xl:text-8xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl"
                              >
                                   DAILY BONUS
                              </h1>
                              <p
                                   class="hidden lg:block text-white mt-6 md:mt-10 mb-28 lg:mb-12 xl:mb-16 font-helveticaLight tracking-wide w-full text-center font-normal text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl"
                              >
                                   Loyalty pays off! Come back everyday and reap
                                   the rewards
                                   <br />
                                   of being a dedicated player.
                              </p>
                              <p
                                   class="block lg:hidden text-white mt-6 md:mt-10 xs:mb-28 lg:mb-12 xl:mb-16 font-helveticaLight tracking-wide w-full text-center font-normal text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl"
                              >
                                   Loyalty pays off! Come back everyday and reap
                                   the rewards of being a dedicated player.
                              </p>
                         </div>

                         <!-- Modal body -->
                         <div
                              class="hidden lg:block pb-5 daily-bonus-container-wrap overflow-x-auto lg:overflow-x-hidden"
                         >
                              <img
                                   class="lg:w-2/12 absolute right-3 lg:top-22 xl:top-24 swing-reverse"
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/cow.png'
                                   "
                                   alt=""
                              />
                              <img
                                   class="absolute left-5 lg:top-20 xl:top-32 swing"
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/bee.png'
                                   "
                                   alt=""
                              />
                              <img
                                   class="absolute -left-32 lg:top-7 xl:top-18 z-10 grow pulse"
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/gold-left.png'
                                   "
                                   alt=""
                              />
                              <img
                                   class="lg:w-2/12 absolute -right-36 bottom-16 z-10 grow pulse"
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/gold-bottom.png'
                                   "
                                   alt=""
                              />
                              <div
                                   class="inline-flex lg:flex flex-row items-center justify-center daily-bonus-wrapper gap-3 lg:gap-3 xl:gap-4"
                              >
                                   <div
                                        v-for="(item, key) in details"
                                        :key="key"
                                        class="daily-bonus rounded-xl pt-3 pb-4 flex flex-col justify-center items-center relative"
                                        :class="
                                             dailyBonus?.consecutive + 1 ==
                                             key + 1
                                                  ? 'w-48 sm:w-60 lg:w-78 xl:w-80 stuck-out'
                                                  : 'w-40 sm:w-48 lg:w-58 xl:w-64 stuck-in'
                                        "
                                   >
                                        <h3
                                             class="text-white whitespace-nowrap font-helvetica font-medium tracking-wider uppercase"
                                             :class="[
                                                  claimStatus != null
                                                       ? dailyBonus?.consecutive >=
                                                         key + 1
                                                            ? 'invisible'
                                                            : 'visible'
                                                       : dailyBonus?.consecutive >=
                                                           key + 1
                                                         ? 'invisible'
                                                         : 'visible',
                                                  dailyBonus?.consecutive + 1 ==
                                                  key + 1
                                                       ? 'text-3xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-6xl 2xl:text-5xl leading-3xl sm:leading-5xl md:leading-5xl lg:leading-5xl xl:leading-6xl 2xl:leading-5xl'
                                                       : 'text-2xl sm:text-4xl md:text-4xl lg:text-3xl xl:text-5xl 2xl:text-4xl leading-2xl sm:leading-5xl md:leading-4xl lg:leading-3xl xl:leading-5xl 2xl:leading-4xl'
                                             ]"
                                        >
                                             DAY {{ key + 1 }}
                                        </h3>
                                        <img
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/modal/bag.png'
                                             "
                                             alt="chicken"
                                             class="mt-4 mb-3"
                                             :class="
                                                  dailyBonus?.consecutive + 1 ==
                                                  key + 1
                                                       ? 'w-6/12 lg:w-7/12'
                                                       : 'w-5/12 lg:w-6/12'
                                             "
                                        />
                                        <div
                                             class="h-full w-full flex flex-col justify-center items-center gap-1 lg:gap-1 xl:gap-2"
                                        >
                                             <div
                                                  class="flex flex-row items-center gap-2 sm:gap-3 lg:gap-2"
                                             >
                                                  <img
                                                       class=""
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/GC.svg'
                                                       "
                                                       alt=""
                                                       :class="
                                                            dailyBonus?.consecutive +
                                                                 1 ==
                                                            key + 1
                                                                 ? 'w-5 sm:w-6 lg:w-7 xl:w-7 h-5 sm:h-6 lg:h-7 xl:h-7'
                                                                 : 'w-5 sm:w-5 lg:w-5 xl:w-7 h-5 sm:h-5 lg:h-5 xl:h-7'
                                                       "
                                                  />
                                                  <h3
                                                       class="w-max text-white font-helvetica font-normal whitespace-nowrap tracking-wider uppercase"
                                                       :class="
                                                            dailyBonus?.consecutive +
                                                                 1 ==
                                                            key + 1
                                                                 ? 'text-2xl sm:text-3xl lg:text-3xl xl:text-4xl leading-2xl sm:leading-3xl lg:leading-3xl xl:leading-4xl'
                                                                 : 'text-xl sm:text-2xl lg:text-xl xl:text-3xl leading-xl sm:leading-2xl lg:leading-xl xl:leading-3xl'
                                                       "
                                                  >
                                                       {{
                                                            money(
                                                                 item.gold_coins
                                                            )
                                                       }}
                                                  </h3>
                                             </div>
                                             <div
                                                  class="flex flex-row items-center gap-4 sm:gap-3 lg:gap-3"
                                             >
                                                  <img
                                                       class=""
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/SC.svg'
                                                       "
                                                       alt=""
                                                       :class="
                                                            dailyBonus?.consecutive +
                                                                 1 ==
                                                            key + 1
                                                                 ? 'w-5 sm:w-6 lg:w-7 xl:w-7 h-5 sm:h-6 lg:h-7 xl:h-7'
                                                                 : 'w-5 sm:w-5 lg:w-5 xl:w-7 h-5 sm:h-5 lg:h-5 xl:h-7'
                                                       "
                                                  />
                                                  <p
                                                       class="w-full text-tertiary-3 font-roboto font-bold tracking-wider text-center"
                                                       :class="
                                                            dailyBonus?.consecutive +
                                                                 1 ==
                                                            key + 1
                                                                 ? 'text-base sm:text-xl lg:text-xl xl:text-2xl'
                                                                 : 'text-sm sm:text-base lg:text-xs xl:text-xl'
                                                       "
                                                  >
                                                       FREE
                                                       {{
                                                            money(
                                                                 item.silver_coins
                                                            )
                                                       }}
                                                  </p>
                                             </div>
                                        </div>
                                        <div
                                             v-if="
                                                  dailyBonus?.consecutive >=
                                                  key + 1
                                             "
                                             class="absolute top-0 left-0 w-full h-full rounded-xl flex flex-col justify-between items-center pt-3 pb-4"
                                             style="
                                                  background: rgba(
                                                       0,
                                                       0,
                                                       0,
                                                       0.4
                                                  );
                                             "
                                        >
                                             <h3
                                                  class="text-white whitespace-nowrap font-helvetica font-medium tracking-wider uppercase"
                                                  :class="[
                                                       dailyBonus?.consecutive +
                                                            1 ==
                                                       key + 1
                                                            ? 'text-3xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-6xl 2xl:text-5xl leading-3xl sm:leading-5xl md:leading-5xl lg:leading-5xl xl:leading-6xl 2xl:leading-5xl'
                                                            : 'text-2xl sm:text-4xl md:text-4xl lg:text-3xl xl:text-5xl 2xl:text-4xl leading-2xl sm:leading-5xl md:leading-4xl lg:leading-3xl xl:leading-5xl 2xl:leading-4xl'
                                                  ]"
                                             >
                                                  Claimed
                                             </h3>
                                             <span
                                                  class="icon-claimed"
                                                  :class="
                                                       dailyBonus?.consecutive +
                                                            1 ==
                                                       key + 1
                                                            ? 'text-6xl sm:text-7xl md:text-8xl lg:text-8xl xl:text-9xl'
                                                            : 'text-5xl sm:text-6xl md:text-7xl lg:text-7xl xl:text-8xl'
                                                  "
                                             ></span>
                                             <div
                                                  class="flex flex-col justify-center items-center gap-2 sm:gap-3 lg:gap-1 xl:gap-2 invisible"
                                             >
                                                  <div
                                                       class="flex flex-row items-center gap-2 sm:gap-4 lg:gap-2"
                                                  >
                                                       <h3
                                                            class="w-max text-white font-helvetica font-normal whitespace-nowrap tracking-wider uppercase"
                                                            :class="
                                                                 dailyBonus?.consecutive +
                                                                      1 ==
                                                                 key + 1
                                                                      ? 'text-2xl sm:text-3xl lg:text-3xl xl:text-4xl leading-2xl sm:leading-3xl lg:leading-3xl xl:leading-4xl'
                                                                      : 'text-xl sm:text-2xl lg:text-xl xl:text-3xl leading-xl sm:leading-2xl lg:leading-xl xl:leading-3xl'
                                                            "
                                                       >
                                                            00
                                                       </h3>
                                                  </div>
                                                  <div
                                                       class="flex flex-row items-center gap-4 sm:gap-8 lg:gap-3"
                                                  >
                                                       <p
                                                            class="w-full text-tertiary-3 font-roboto font-bold tracking-wider text-center"
                                                            :class="
                                                                 dailyBonus?.consecutive +
                                                                      1 ==
                                                                 key + 1
                                                                      ? 'text-base sm:text-xl lg:text-xl xl:text-2xl'
                                                                      : 'text-sm sm:text-base lg:text-xs xl:text-xl'
                                                            "
                                                       >
                                                            00
                                                       </p>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div class="hidden xs:flex justify-center lg:hidden">
                              <img
                                   class="xs:w-4/12 sm:w-3/12 absolute -right-6 sm:-right-10 xs:top-40 sm:top-36 md:top-32"
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/cow.png'
                                   "
                                   alt=""
                              />
                              <img
                                   class="w-3/12 sm:w-3/12 absolute -left-6 sm:-left-10 xs:top-48 sm:top-40 md:top-36"
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/bee.png'
                                   "
                                   alt=""
                              />
                              <img
                                   class="w-26 md:w-30 absolute xs:top-44 sm:top-48 md:top-52"
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/gold-bottom.png'
                                   "
                                   alt=""
                              />
                         </div>
                         <div class="block lg:hidden">
                              <Carousel
                                   :itemsToShow="1.5"
                                   :transition="500"
                                   :modelValue="
                                        dailyBonus?.consecutive + getPoints()
                                   "
                                   key="daily-carousel"
                                   class="w-full daily-carousel h-78 xs:h-78 sm:h-80 md:h-96"
                                   :breakpoints="{
                                        420: {
                                             itemsToShow: 2.5
                                        }
                                   }"
                              >
                                   <Slide
                                        v-for="(item, key) in details"
                                        :key="key"
                                        class="daily-bonus rounded-xl pt-3 pb-4 flex flex-col justify-center items-center relative h-max !mx-1 sm:!mx-2 md:!mx-3"
                                   >
                                        <h3
                                             class="text-white whitespace-nowrap font-helvetica font-medium tracking-wider uppercase"
                                             :class="[
                                                  dailyBonus?.consecutive >=
                                                  key + 1
                                                       ? 'invisible'
                                                       : 'visible',
                                                  dailyBonus?.consecutive + 1 ==
                                                  key + 1
                                                       ? 'text-3xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-6xl 2xl:text-5xl leading-3xl sm:leading-5xl md:leading-5xl lg:leading-5xl xl:leading-6xl 2xl:leading-5xl'
                                                       : 'text-2xl sm:text-4xl md:text-4xl lg:text-3xl xl:text-5xl 2xl:text-4xl leading-2xl sm:leading-5xl md:leading-4xl lg:leading-3xl xl:leading-5xl 2xl:leading-4xl'
                                             ]"
                                        >
                                             DAY {{ key + 1 }}
                                        </h3>
                                        <img
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/modal/bag.png'
                                             "
                                             alt="chicken"
                                             class="mt-4 mb-3"
                                             :class="
                                                  dailyBonus?.consecutive + 1 ==
                                                  key + 1
                                                       ? 'w-6/12 lg:w-7/12'
                                                       : 'w-5/12 lg:w-6/12'
                                             "
                                        />
                                        <div
                                             class="h-full w-full flex flex-col justify-center items-center gap-1 lg:gap-1 xl:gap-2"
                                        >
                                             <div
                                                  class="flex flex-row items-center gap-2 sm:gap-3 lg:gap-2"
                                             >
                                                  <img
                                                       class=""
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/GC.svg'
                                                       "
                                                       alt=""
                                                       :class="
                                                            dailyBonus?.consecutive +
                                                                 1 ==
                                                            key + 1
                                                                 ? 'w-5 sm:w-6 lg:w-7 xl:w-7 h-5 sm:h-6 lg:h-7 xl:h-7'
                                                                 : 'w-5 sm:w-5 lg:w-5 xl:w-7 h-5 sm:h-5 lg:h-5 xl:h-7'
                                                       "
                                                  />
                                                  <h3
                                                       class="w-max text-white font-helvetica font-normal whitespace-nowrap tracking-wider uppercase"
                                                       :class="
                                                            dailyBonus?.consecutive +
                                                                 1 ==
                                                            key + 1
                                                                 ? 'text-2xl sm:text-3xl lg:text-3xl xl:text-4xl leading-2xl sm:leading-3xl lg:leading-3xl xl:leading-4xl'
                                                                 : 'text-xl sm:text-2xl lg:text-xl xl:text-3xl leading-xl sm:leading-2xl lg:leading-xl xl:leading-3xl'
                                                       "
                                                  >
                                                       {{
                                                            money(
                                                                 item.gold_coins
                                                            )
                                                       }}
                                                  </h3>
                                             </div>
                                             <div
                                                  class="flex flex-row items-center gap-4 sm:gap-3 lg:gap-3"
                                             >
                                                  <img
                                                       class=""
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/SC.svg'
                                                       "
                                                       alt=""
                                                       :class="
                                                            dailyBonus?.consecutive +
                                                                 1 ==
                                                            key + 1
                                                                 ? 'w-5 sm:w-6 lg:w-7 xl:w-7 h-5 sm:h-6 lg:h-7 xl:h-7'
                                                                 : 'w-5 sm:w-5 lg:w-5 xl:w-7 h-5 sm:h-5 lg:h-5 xl:h-7'
                                                       "
                                                  />
                                                  <p
                                                       class="w-full text-tertiary-3 font-roboto font-bold tracking-wider text-center"
                                                       :class="
                                                            dailyBonus?.consecutive +
                                                                 1 ==
                                                            key + 1
                                                                 ? 'text-base sm:text-xl lg:text-xl xl:text-2xl'
                                                                 : 'text-sm sm:text-base lg:text-xs xl:text-xl'
                                                       "
                                                  >
                                                       FREE
                                                       {{
                                                            money(
                                                                 item.silver_coins
                                                            )
                                                       }}
                                                  </p>
                                             </div>
                                        </div>
                                        <div
                                             v-if="
                                                  dailyBonus?.consecutive >=
                                                  key + 1
                                             "
                                             class="absolute top-0 left-0 w-full h-full rounded-xl flex flex-col justify-between items-center pt-3 pb-4"
                                             style="
                                                  background: rgba(
                                                       0,
                                                       0,
                                                       0,
                                                       0.4
                                                  );
                                             "
                                        >
                                             <h3
                                                  class="text-white whitespace-nowrap font-helvetica font-medium tracking-wider uppercase"
                                                  :class="[
                                                       dailyBonus?.consecutive +
                                                            1 ==
                                                       key + 1
                                                            ? 'text-3xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-6xl 2xl:text-5xl leading-3xl sm:leading-5xl md:leading-5xl lg:leading-5xl xl:leading-6xl 2xl:leading-5xl'
                                                            : 'text-2xl sm:text-4xl md:text-4xl lg:text-3xl xl:text-5xl 2xl:text-4xl leading-2xl sm:leading-5xl md:leading-4xl lg:leading-3xl xl:leading-5xl 2xl:leading-4xl'
                                                  ]"
                                             >
                                                  Claimed
                                             </h3>
                                             <span
                                                  class="icon-claimed"
                                                  :class="
                                                       dailyBonus?.consecutive +
                                                            1 ==
                                                       key + 1
                                                            ? 'text-6xl sm:text-7xl md:text-8xl lg:text-8xl xl:text-9xl'
                                                            : 'text-5xl sm:text-6xl md:text-7xl lg:text-7xl xl:text-8xl'
                                                  "
                                             ></span>
                                             <div
                                                  class="flex flex-col justify-center items-center gap-2 sm:gap-3 lg:gap-1 xl:gap-2 invisible"
                                             >
                                                  <div
                                                       class="flex flex-row items-center gap-2 sm:gap-4 lg:gap-2"
                                                  >
                                                       <h3
                                                            class="w-max text-white font-helvetica font-normal whitespace-nowrap tracking-wider uppercase"
                                                            :class="
                                                                 dailyBonus?.consecutive +
                                                                      1 ==
                                                                 key + 1
                                                                      ? 'text-2xl sm:text-3xl lg:text-3xl xl:text-4xl leading-2xl sm:leading-3xl lg:leading-3xl xl:leading-4xl'
                                                                      : 'text-xl sm:text-2xl lg:text-xl xl:text-3xl leading-xl sm:leading-2xl lg:leading-xl xl:leading-3xl'
                                                            "
                                                       >
                                                            00
                                                       </h3>
                                                  </div>
                                                  <div
                                                       class="flex flex-row items-center gap-4 sm:gap-8 lg:gap-3"
                                                  >
                                                       <p
                                                            class="w-full text-tertiary-3 font-roboto font-bold tracking-wider text-center"
                                                            :class="
                                                                 dailyBonus?.consecutive +
                                                                      1 ==
                                                                 key + 1
                                                                      ? 'text-base sm:text-xl lg:text-xl xl:text-2xl'
                                                                      : 'text-sm sm:text-base lg:text-xs xl:text-xl'
                                                            "
                                                       >
                                                            00
                                                       </p>
                                                  </div>
                                             </div>
                                        </div>
                                   </Slide>
                                   <Slide :key="6"></Slide>

                                   <template #addons>
                                        <!-- <Pagination /> -->
                                   </template>
                              </Carousel>
                         </div>
                         <div
                              class="flex justify-center items-center sm:mt-18 px-7 lg:px-0 z-10 relative"
                         >
                              <div
                                   @click="
                                        () => {
                                             if (claimStatus == null) {
                                                  onClaim();
                                             }
                                        }
                                   "
                                   class="w-full lg:w-6/12 rounded-full font-roboto font-extrabold tracking-wider text-center px-10 py-3 text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl"
                                   :class="
                                        !claimLoading
                                             ? claimStatus == null
                                                  ? 'bg-secondary-3 text-primary-1 cursor-pointer'
                                                  : !claimStatus.status
                                                    ? 'bg-gray-190 text-shade-7 cursor-not-allowed'
                                                    : 'bg-secondary-3 text-primary-1 cursor-not-allowed'
                                             : 'bg-secondary-3 text-primary-1 cursor-not-allowed'
                                   "
                              >
                                   {{
                                        !claimLoading
                                             ? claimStatus == null
                                                  ? "Claim"
                                                  : claimStatus.label
                                             : claimStatus == null
                                               ? "Please Wait ..."
                                               : claimStatus.label
                                   }}
                              </div>
                         </div>
                         <img
                              v-if="claimStatus?.status"
                              class="w-full absolute bottom-7"
                              :src="baseUrl.img + '/assets/img/modal/bonus.gif'"
                              alt=""
                         />
                    </div>
               </div>
          </div>
     </transition>
     <loading-overlay
          :isLoading="claimLoading"
          class="z-100"
     />
     <div
          v-if="isDailyBonus"
          class="fixed inset-0 z-60"
          style="background: rgba(0, 0, 0, 0.75)"
     ></div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import eventBus from "plugins/event.js";
import { useRouter } from "vue-router";
import { claimDaily as claimDailyAPI } from "api/bonus.js";
import { useFormatter } from "composables/use-formatter.js";
import { authStore } from "store/auth.js";
import { bonusStore } from "store/bonus.js";
import { utilitiesStore } from "store/utilities.js";
import { Carousel, Slide } from "vue3-carousel";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import { baseUrl } from "config/services.js";

export default {
     props: ["isClaimed"],
     components: {
          Carousel,
          Slide,
          LoadingOverlay
     },
     setup(props) {
          const { money } = useFormatter();
          const useUtilitiesStore = utilitiesStore();

          const windowOuterWidth = ref(window.outerWidth);

          const claimLoading = ref(false);
          const claimStatus = ref(null);

          const router = useRouter();
          const isDailyBonus = ref(false);

          const difference = ref(null);

          const useAuthStore = authStore();
          const useBonusStore = bonusStore();

          const token = computed(() => {
               return useAuthStore.token;
          });

          const dailyBonus = computed(() => {
               return useBonusStore.dailyBonus?.["daily"] || {};
          });

          const details = computed(() => {
               return useBonusStore.details["daily"];
          });

          const claimableDaily = computed(() => {
               return useBonusStore.claimable_daily["daily"];
          });

          // async function loadParticles(options) {
          //      await loadConfettiPreset(tsParticles);

          //      await tsParticles.load({ id: "tsparticles2", options });
          // }

          // const configs = {
          //      fpsLimit: 60,
          //      // detectRetina: true,
          //      fullScreen: {
          //           enable: true,
          //      },
          //      background: {
          //           color: "transparent",
          //      },
          //      preset: "confetti",
          //      particles: {
          //           color: {
          //                value: [
          //                     "#ffffff",
          //                     "#E70012",
          //                     "#43B047",
          //                     "#0870FD",
          //                     "#FFB800",
          //                     "#0F0F0F",
          //                ],
          //           },
          //           shape: {
          //                type: ["circle", "square"],
          //           },
          //           opacity: {
          //                value: { min: 0, max: 1 },
          //                animation: {
          //                     enable: true,
          //                     speed: 2,
          //                     startValue: "max",
          //                     destroy: "min",
          //                },
          //           },
          //           size: {
          //                value: { min: 3, max: 7 },
          //           },
          //           life: {
          //                duration: {
          //                     sync: true,
          //                     value: 5,
          //                },
          //                count: 1,
          //           },
          //           move: {
          //                enable: true,
          //                gravity: {
          //                     enable: true,
          //                     acceleration: 20,
          //                },
          //                speed: 80,
          //                decay: 0.1,
          //                direction: "none",
          //                random: false,
          //                straight: false,
          //                outModes: {
          //                     default: "destroy",
          //                     top: "none",
          //                },
          //           },
          //           rotate: {
          //                value: {
          //                     min: 0,
          //                     max: 360,
          //                },
          //                direction: "random",
          //                move: true,
          //                animation: {
          //                     enable: true,
          //                     speed: 60,
          //                },
          //           },
          //           tilt: {
          //                direction: "random",
          //                enable: true,
          //                move: true,
          //                value: {
          //                     min: 0,
          //                     max: 360,
          //                },
          //                animation: {
          //                     enable: true,
          //                     speed: 60,
          //                },
          //           },
          //           roll: {
          //                darken: {
          //                     enable: true,
          //                     value: 25,
          //                },
          //                enable: true,
          //                speed: {
          //                     min: 15,
          //                     max: 25,
          //                },
          //           },
          //           wobble: {
          //                distance: 30,
          //                enable: true,
          //                move: true,
          //                speed: {
          //                     min: -15,
          //                     max: 15,
          //                },
          //           },
          //      },
          //      emitters: [
          //           {
          //                direction: "top-right",
          //                rate: {
          //                     delay: 0.1,
          //                     quantity: 3,
          //                },
          //                position: {
          //                     x: 0,
          //                     y: 100,
          //                },
          //                size: {
          //                     width: 0,
          //                     height: 0,
          //                },
          //                particles: {
          //                     move: {
          //                          angle: {
          //                               offset: -15,
          //                               value: 60,
          //                          },
          //                     },
          //                },
          //           },
          //           {
          //                direction: "top-left",
          //                rate: {
          //                     delay: 0.1,
          //                     quantity: 5,
          //                },
          //                position: {
          //                     x: 100,
          //                     y: 100,
          //                },
          //                size: {
          //                     width: 0,
          //                     height: 0,
          //                },
          //                particles: {
          //                     move: {
          //                          angle: {
          //                               offset: 15,
          //                               value: 60,
          //                          },
          //                     },
          //                },
          //           },
          //      ],
          // };

          const toggleDailyBonus = () => {
               isDailyBonus.value = !isDailyBonus.value;
          };

          const closeModal = () => {
               isDailyBonus.value = false;
               useUtilitiesStore.enableScroll();
               router.replace({});
          };

          const handleBackdropClick = (e) => {
               e.preventDefault();
               e.stopPropagation();
               closeModal(); // Close modal when backdrop is clicked
          };

          async function onClaim() {
               if (claimStatus?.value?.status == true) {
                    return;
               }

               claimLoading.value = true;
               await claimDailyAPI("daily").then(async (res) => {
                    await useBonusStore.getDaily("daily");
                    useBonusStore.setClaimableDaily(false, "daily");
                    setTimeout(() => {
                         claimLoading.value = false;
                         claimStatus.value = {
                              label: "Daily Reward Succesfully Claimed",
                              status: res.status
                         };
                         if (res?.status) {
                              // loadParticles(configs);
                              useAuthStore.getUser(true);
                              reloadBalance();
                         }
                         setTimeout(() => {
                              // claimLoading.value = true;
                              // const count = setInterval(() => {
                              //      claimCount.value = claimCount.value + 1;
                              // }, 1000);
                              setTimeout(() => {
                                   // clearInterval(count);
                                   claimLoading.value = false;
                                   claimStatus.value = {
                                        label: "CLAIMED",
                                        status: false
                                   };
                              }, 3000);
                         }, 5000);
                    }, 2000);
               });
          }

          const reloadBalance = async () => {
               try {
                    await useAuthStore.reloadBalance({ type: "gold_coins" });
                    await useAuthStore.reloadBalance({ type: "silver_coins" });
               } catch (error) {
                    console.error("Error gatting balance:", error);
               }
          };

          const getPoints = () => {
               return windowOuterWidth.value >= 768
                    ? 0.3
                    : windowOuterWidth.value >= 640
                      ? 0.2
                      : 0.15;
          };

          onMounted(async () => {
               if (props.isClaimed == false) {
                    claimStatus.value = {
                         label: "CLAIMED",
                         status: false
                    };
               }
               eventBus.on("open:dailybonus", () => {
                    useUtilitiesStore.disableScroll();
                    toggleDailyBonus();
               });
               if (token.value) {
                    await useBonusStore.getDaily("daily");
               }

               window.addEventListener("resize", () => {
                    windowOuterWidth.value = window.outerWidth;
               });
          });

          onUnmounted(() => {
               eventBus.off("open:dailybonus");
          });

          watch(props, (newVal) => {
               if (newVal) {
                    if (newVal.isClaimed == false) {
                         claimStatus.value = {
                              label: "CLAIMED",
                              status: false
                         };
                    } else {
                         claimStatus.value = null;
                    }
               }
          });

          return {
               isDailyBonus,
               closeModal,
               dailyBonus,
               details,
               money,
               onClaim,
               difference,
               claimableDaily,
               claimLoading,
               claimStatus,
               windowOuterWidth,
               getPoints,
               baseUrl,
               handleBackdropClick
               // claimCount,
          };
     }
};
</script>
