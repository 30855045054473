<template>
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               v-if="isOpenTier"
               id="authentication-modal"
               tabindex="-1"
               aria-hidden="true"
               class="overflow-y-auto overflow-x-hidden fixed z-80 max-h-full transform ease-in-out transition-all duration-300 self-center w-full m-auto inset-0 font-helveticaLight flex justify-center items-center"
          >
               <div
                    class="relative px-6 py-3 sm:py-4 sm:px-10 w-full max-w-lg max-h-full m-auto text-center"
               >
                    <!-- Modal content -->
                    <div
                         class="relative bg-gradient-benefits rounded-xl shadow-xl"
                    >
                         <button
                              @click="closeModal()"
                              type="button"
                              class="close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
                              data-modal-hide="authentication-modal"
                         >
                              <img
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/exclude.svg'
                                   "
                                   alt=""
                              />
                              <span class="sr-only">Close modal</span>
                         </button>
                         <div
                              class="flex flex-col items-center justify-center py-6 lg:py-8 font-roboto text-shade-2 text-sm xs:text-base px-4 sm:px-6 lg:px-8 tracking-wide leading-tight"
                         >
                              <div
                                   v-if="tier != 'not-tier' && tier != null"
                                   class="mt-3 flex flex-row gap-5 justify-between w-full relative h-max"
                                   :class="
                                        tier.toLowerCase() != 'black'
                                             ? 'items-start'
                                             : 'items-center'
                                   "
                              >
                                   <img
                                        class="h-24 w-auto"
                                        :src="
                                             baseUrl.img +
                                             '/assets/img/tier/' +
                                             tier +
                                             '.png'
                                        "
                                        alt="badge"
                                   />
                                   <div
                                        class="flex flex-col items-start h-full w-full"
                                   >
                                        <div
                                             class="flex flex-row justify-between w-full"
                                        >
                                             <p
                                                  class="text-white text-sm font-bold mb-1 capitalize"
                                             >
                                                  {{ tier }}
                                             </p>
                                             <p
                                                  v-if="
                                                       tier.toLowerCase() !=
                                                       'black'
                                                  "
                                                  class="text-white text-sm font-normal mb-1 capitalize"
                                             >
                                                  {{
                                                       "Progress " +
                                                       roundUp(
                                                            (parseInt(
                                                                 tierStar
                                                            ) /
                                                                 tierRangeProgress[1][
                                                                      "tier_points"
                                                                 ]) *
                                                                 100
                                                       ) +
                                                       "%"
                                                  }}
                                             </p>
                                             <p
                                                  v-else
                                                  class="text-white text-sm font-normal mb-1 capitalize"
                                             >
                                                  Progress 100%
                                             </p>
                                        </div>
                                        <div
                                             v-if="
                                                  [
                                                       'bronze',
                                                       'silver',
                                                       'gold'
                                                  ].includes(tier)
                                             "
                                             class="tier-progress-wrapper !h-2 rounded-3xl w-full relative mt-3 bg-moozi-6"
                                        >
                                             <div
                                                  class="tier-progress !h-2 rounded-3xl absolute top-0 left-0 bg-moozi-8"
                                                  :style="
                                                       'width:' +
                                                       (parseInt(tierStar) /
                                                            tierRangeProgress[1][
                                                                 'tier_points'
                                                            ]) *
                                                            100 +
                                                       '%'
                                                  "
                                             ></div>
                                        </div>
                                        <div
                                             v-else
                                             class="tier-progress-wrapper !h-2 rounded-3xl w-full relative mt-3 bg-moozi-6"
                                        >
                                             <div
                                                  class="tier-progress !h-2 rounded-3xl absolute top-0 left-0 bg-moozi-8"
                                                  style="width: 100%"
                                             ></div>
                                        </div>
                                        <div
                                             v-if="
                                                  tier.toLowerCase() != 'black'
                                             "
                                             class="w-full flex flex-row justify-end items-center gap-3 relative mt-5"
                                        >
                                             <p
                                                  class="text-white text-sm font-bold mb-1 capitalize"
                                             >
                                                  Next level
                                                  <span
                                                       class="capitalize font-normal"
                                                  >
                                                       {{
                                                            tierRangeProgress[1][
                                                                 "tier_name"
                                                            ].toLowerCase()
                                                       }}
                                                  </span>
                                             </p>
                                             <img
                                                  class="h-10 w-auto"
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/tier/' +
                                                       tierRangeProgress[1][
                                                            'tier_name'
                                                       ].toLowerCase() +
                                                       '.png'
                                                  "
                                                  alt="badge"
                                             />
                                        </div>
                                   </div>
                              </div>
                              <div
                                   v-if="tier != 'not-tier' && tier != null"
                                   class="h-2 my-4 w-full border-b-2 border-solid !border-moozi-6"
                              ></div>
                              <div
                                   v-else
                                   class="mb-5"
                              >
                                   <div class="flex flex-row">
                                        <img
                                             class="h-20 w-auto"
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/tier/bronze.png'
                                             "
                                             alt="badge"
                                        />
                                        <img
                                             class="h-20 -ml-2 w-auto"
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/tier/silver.png'
                                             "
                                             alt="badge"
                                        />
                                        <img
                                             class="h-20 -ml-2 w-auto"
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/tier/gold.png'
                                             "
                                             alt="badge"
                                        />
                                        <img
                                             class="h-20 -ml-2 w-auto"
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/tier/diamond.png'
                                             "
                                             alt="badge"
                                        />
                                   </div>
                              </div>
                              <p
                                   class="font-normal font-roboto text-white text-sm text-start"
                              >
                                   Earning VIP points on Moozi is simple and
                                   easy! Play games, log in daily, or refer
                                   friends to accumulate points. As you gather
                                   points, you'll level up your rank and unlock
                                   exciting features and rewards!
                              </p>
                              <p
                                   @click="gotoPromotion"
                                   class="mt-5 cursor-pointer font-bold flex flex-row justify-center gap-2 items-center font-roboto text-sm text-center w-full text-moozi-6"
                              >
                                   Learn more
                                   <svg
                                        width="8"
                                        height="14"
                                        viewBox="0 0 8 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                   >
                                        <path
                                             d="M7.66879 6.18665C8.1104 6.63659 8.1104 7.38072 7.66879 7.83066L1.94482 13.6625C1.5032 14.1125 0.772823 14.1125 0.33121 13.6625C-0.110403 13.2126 -0.110403 12.4685 0.33121 12.0185L5.25688 7L0.348211 1.98146C-0.0934015 1.53152 -0.0934015 0.787392 0.348211 0.337454C0.789824 -0.112485 1.52015 -0.112485 1.96177 0.337454L7.68574 6.16934L7.66879 6.18665Z"
                                             fill="#9D6465"
                                        />
                                   </svg>
                              </p>
                         </div>
                    </div>
               </div>
          </div>
     </transition>
     <div
          v-if="isOpenTier"
          class="fixed inset-0 z-60"
          style="background-color: rgba(0, 0, 0, 0.6)"
     ></div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import eventBus from "plugins/event.js";
import { utilitiesStore } from "store/utilities.js";
import { baseUrl } from "config/services.js";
import { useFormatter } from "composables/use-formatter.js";
import { useRouter } from "vue-router";

export default {
     setup() {
          const env = import.meta.env.VITE_ENV;

          const { money } = useFormatter();

          const router = useRouter();

          const isOpenTier = ref(false);
          const useUtilitiesStore = utilitiesStore();

          const tier = ref(null);
          const tierStar = ref(null);
          const tierRangeProgress = ref(null);

          const closeModal = () => {
               isOpenTier.value = false;
               useUtilitiesStore.enableScroll();
          };

          const gotoPromotion = () => {
               closeModal();
               router.push({
                    path:
                         "/promotion/detail/Moozi-Loyalty-Program-" +
                         (env == "production" ? "1028" : "32")
               });
          };

          const roundUp = (data) => {
               return Math.round(data);
          };

          onMounted(async () => {
               eventBus.on("open:tier", (data) => {
                    tier.value = data?.tier;
                    tierStar.value = data?.tierStar;
                    tierRangeProgress.value = data?.tierRangeProgress;

                    useUtilitiesStore.disableScroll();
                    isOpenTier.value = true;
               });
          });

          onUnmounted(() => {
               eventBus.off("open:tier");
          });

          return {
               isOpenTier,
               closeModal,
               baseUrl,
               tier,
               tierStar,
               tierRangeProgress,
               money,
               gotoPromotion,
               roundUp
          };
     }
};
</script>
