<template>
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               v-if="isOpenReminder"
               id="authentication-modal"
               tabindex="-1"
               aria-hidden="true"
               :class="{ hidden: deviceType() != 'mobile' }"
               class="overflow-y-auto overflow-x-hidden fixed bottom-0 z-80 transform ease-in-out transition-all duration-300 self-center w-full font-helveticaLight"
          >
               <div
                    class="relative px-3 py-3 w-full max-w-lg max-h-full text-center"
               >
                    <!-- Modal content -->
                    <div class="relative bg-shade-1 rounded-xl shadow-xl">
                         <div
                              class="flex flex-row items-start gap-1 divide-y divide-shade-12 justify-center py-3 font-roboto text-shade-2 text-sm xs:text-base px-3 tracking-wide leading-tight"
                         >
                              <div class="w-full font-normal">
                                   <div
                                        class="md:px-6 lg:px-10"
                                        v-html="
                                             text[isIOS() ? 'ios' : 'android']
                                        "
                                   ></div>
                              </div>
                              <button
                                   @click="closeModal()"
                                   type="button"
                                   class="close-btn-c rounded-xl text-sm ms-auto inline-flex justify-center items-center"
                                   data-modal-hide="authentication-modal"
                              >
                                   <img
                                        :src="
                                             baseUrl.img +
                                             '/assets/img/modal/exclude.svg'
                                        "
                                        alt=""
                                   />
                                   <span class="sr-only">Close modal</span>
                              </button>
                         </div>
                    </div>
               </div>
          </div>
     </transition>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import eventBus from "plugins/event.js";
import { baseUrl } from "config/services.js";
import device from "middleware/device.js";
import Cookies from "js-cookie";
import { text } from "constants/add-to-home-screen.js";

export default {
     setup() {
          const isOpenReminder = ref(false);

          const { isIOS, deviceType } = device();

          const closeModal = () => {
               isOpenReminder.value = false;
               Cookies.set("add-to-home-screen-prompt", true);
          };

          onMounted(async () => {
               eventBus.on("open:add-to-home-screen", () => {
                    isOpenReminder.value = true;
               });
          });

          onUnmounted(() => {
               eventBus.off("open:add-to-home-screen");
          });

          return {
               isOpenReminder,
               closeModal,
               baseUrl,
               isIOS,
               text,
               deviceType
          };
     }
};
</script>
