<template>
     <div>
          <div
               @click="onTier"
               v-if="tier != 'not-tier' && tier != null"
               class="tier tier-wrapper h-30 relative rounded-xl p-2 flex flex-col items-center w-full mt-10 md:mt-5 mb-5 cursor-pointer"
               :class="tier"
          >
               <div
                    class="flex flex-row justify-between items-center w-full relative h-max"
               >
                    <img
                         class="h-16 w-auto"
                         :src="
                              baseUrl.img + '/assets/img/tier/' + tier + '.png'
                         "
                         alt="badge"
                    />
                    <div class="flex flex-col items-center h-full w-full">
                         <p
                              class="tier-label text-sm font-bold mb-1 capitalize"
                         >
                              {{ tier }}
                         </p>
                         <div
                              v-if="tierRangeProgress && tier"
                              class="tier-badge flex flex-row justify-center items-center px-2 py-1 gap-1 rounded-3xl"
                         >
                              <p class="text-xs font-bold text-white">
                                   {{ tierStatus() }}
                              </p>
                         </div>
                         <div
                              v-if="tier.toLowerCase() == 'black'"
                              class="tier-badge flex flex-row justify-center items-center px-2 py-1 gap-1 rounded-3xl"
                         >
                              <p class="text-xs font-bold text-white">
                                   Invitation only
                              </p>
                         </div>
                    </div>
               </div>
               <div v-if="tier.toLowerCase() == 'black'">
                    <p
                         class="mt-3 tier-description text-xs text-center leading-3 font-medium capitalize"
                    >
                         Top-tier exclusive benefits
                    </p>
               </div>
               <div
                    v-else-if="tierRangeProgress"
                    class="flex flex-col items-center justify-center w-full h-full"
               >
                    <div
                         v-if="['bronze', 'silver', 'gold'].includes(tier)"
                         class="tier-progress-wrapper rounded-3xl w-full relative mt-3"
                    >
                         <div
                              class="tier-progress rounded-3xl absolute top-0 left-0"
                              :style="
                                   'width:' +
                                   (parseInt(tierStar) /
                                        tierRangeProgress[1]['tier_points']) *
                                        100 +
                                   '%'
                              "
                         ></div>
                    </div>
                    <div
                         v-if="['bronze', 'silver', 'gold'].includes(tier)"
                         class="flex flex-row justify-between items-center w-full"
                    >
                         <p
                              class="tier-progress-label mt-2 text-xs leading-3 font-medium capitalize"
                         >
                              {{ tierRangeProgress[0]["tier_name"] }}
                         </p>
                         <p
                              class="tier-progress-label mt-2 text-xs leading-3 font-medium capitalize"
                         >
                              {{ tierRangeProgress[1]["tier_name"] }}
                         </p>
                    </div>
                    <p
                         v-else
                         class="tier-description text-xs text-center leading-3 font-medium capitalize"
                    >
                         {{
                              tier == "diamond"
                                   ? "Prestigious rank brings exclusive rewards"
                                   : ""
                         }}
                    </p>
               </div>
          </div>
          <div
               v-else
               @click="onTier"
               class="tier tier-wrapper not-tier h-30 rounded-xl p-2 flex flex-col justify-center items-center relative mt-10 md:mt-5 mb-5 cursor-pointer"
          >
               <p class="text-sm font-bold mb-1 capitalize">
                    Moozi loyalty program
               </p>
               <div class="flex flex-row">
                    <img
                         class="h-12 w-auto"
                         :src="baseUrl.img + '/assets/img/tier/bronze.png'"
                         alt="badge"
                    />
                    <img
                         class="h-12 -ml-2 w-auto"
                         :src="baseUrl.img + '/assets/img/tier/silver.png'"
                         alt="badge"
                    />
                    <img
                         class="h-12 -ml-2 w-auto"
                         :src="baseUrl.img + '/assets/img/tier/gold.png'"
                         alt="badge"
                    />
                    <img
                         class="h-12 -ml-2 w-auto"
                         :src="baseUrl.img + '/assets/img/tier/diamond.png'"
                         alt="badge"
                    />
               </div>
               <p class="mt-2 text-xs leading-3 font-medium capitalize">
                    We value your loyalty!
               </p>
          </div>
          <!-- <div>
               <p
                    @click="setTierSample"
                    class="cursor-pointer flex justify-center items-center w-full mb-2 underline text-moozi-8 text-xs leading-3 font-medium capitalize"
               >
                    check other tier template
               </p>
          </div> -->
     </div>
</template>
<script>
import { ref, watch, computed } from "vue";
import { baseUrl } from "config/services.js";
import { tierStore } from "store/tier.js";
import { useFormatter } from "composables/use-formatter.js";
import _ from "underscore";
import eventBus from "plugins/event.js";

export default {
     setup() {
          const { money } = useFormatter();

          const tier = ref(null);
          const tierStar = ref(null);
          const tierRangeProgress = ref(null);

          const useTierStore = tierStore();

          const userTier = computed(() => {
               setTier(useTierStore.userTier);
               return useTierStore.userTier;
          });

          const tierList = computed(() => {
               return useTierStore.tierList;
          });

          const setTier = (val) => {
               if (val != null && val?.player_tier) {
                    tier.value = val?.player_tier.toLowerCase();
                    tierStar.value = parseFloat(val?.points);
               }
          };

          const setProgressBarRanges = (val) => {
               const active = tier.value;

               const getIndex = _.findIndex(val, function (params) {
                    return params["tier_name"].toLowerCase() == active;
               });

               if (getIndex != val.length - 1) {
                    tierRangeProgress.value = [
                         val[getIndex],
                         val[getIndex + 1]
                    ];
                    console.log(tierRangeProgress.value);
                    console.log(tierStar.value);
               }
          };

          const onTier = () => {
               const data = {
                    tier: tier.value,
                    tierStar: tierStar.value,
                    tierRangeProgress: tierRangeProgress.value
               };
               eventBus.emit("open:tier", data);
          };

          const roundUp = (data) => {
               return Math.round(data);
          };

          const tierStatus = () => {
               return (
                    "Progress " +
                    roundUp(
                         (parseInt(tierStar.value) /
                              tierRangeProgress.value[1]["tier_points"]) *
                              100
                    ) +
                    "%"
               );
          };

          watch(userTier, (newVal) => {
               setTier(newVal);
          });

          watch(tierList, (newVal) => {
               setProgressBarRanges(newVal);
          });

          return {
               tier,
               tierStar,
               tierRangeProgress,
               baseUrl,
               money,
               onTier,
               roundUp,
               tierStatus
          };
     }
};
</script>
