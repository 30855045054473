<template>
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               v-if="isOpenIdVerification"
               id="authentication-modal"
               tabindex="-1"
               class="overflow-y-auto overflow-x-hidden fixed z-80 max-h-full transform ease-in-out transition-all duration-300 self-center w-full m-auto inset-0 flex justify-center items-center"
          >
               <div
                    class="relative px-3 py-3 sm:py-4 sm:px-6 w-full max-w-lg max-h-full tracking-wider m-auto"
               >
                    <!-- Modal content -->
                    <div class="relative bg-white rounded-lg shadow">
                         <button
                              v-if="!isComplete"
                              @click="closeModal()"
                              type="button"
                              class="close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
                              data-modal-hide="authentication-modal"
                         >
                              <img
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/exclude.svg'
                                   "
                                   alt=""
                              />
                              <span class="sr-only">Close modal</span>
                         </button>
                         <!-- Modal header -->
                         <div
                              class="px-5 flex items-center justify-center mb-1 pt-4"
                         >
                              <h3
                                   class="text-3xl font-semibold text-gray-900 uppercase"
                              >
                                   ID Verification
                              </h3>
                         </div>
                         <div class="px-5 pb-4 font-inter">
                              <div
                                   id="sumsub-websdk-container"
                                   style="
                                        min-height: 20rem;
                                        background-color: #fff;
                                   "
                              ></div>
                              <div
                                   v-if="isComplete"
                                   class="flex items-center justify-center mb-5"
                              >
                                   <button
                                        :id="
                                             isNonDoc && kycStatus == 1
                                                  ? 'successKycNonDoc'
                                                  : 'successKycBasic'
                                        "
                                        type="button"
                                        @click="back2Home()"
                                        class="rounded-full font-roboto font-bold tracking-wider px-10 py-3 bg-secondary-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg cursor-pointer"
                                   >
                                        Back to Home
                                   </button>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </transition>
     <div
          v-if="isOpenIdVerification"
          class="fixed inset-0 z-60"
          style="background: rgba(0, 0, 0, 0.75)"
     ></div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed, watch } from "vue";
import eventBus from "plugins/event.js";
import { authStore } from "store/auth";
import snsWebSdk from "@sumsub/websdk";
import {
     getKYC as getKYCAPI,
     setApplicantID as setApplicantIDAPI,
     kycStatus as kycStatusAPI
} from "api/auth.js";
import { useToaster } from "composables/use-toaster.js";
import { useRouter } from "vue-router";
import { utilitiesStore } from "store/utilities.js";
import { baseUrl } from "config/services.js";
import _ from "underscore";

export default {
     setup() {
          const { successToast } = useToaster();
          const router = useRouter();
          const useAuthStore = authStore();
          const isOpenIdVerification = ref(false);
          const accessToken = ref("");
          const applicantId = ref("");
          const useUtilitiesStore = utilitiesStore();

          const isComplete = ref(false);
          const isNonDoc = ref(false);

          // const toggelIdVerification = () => {
          //      isOpenIdVerification.value = !isOpenIdVerification.value;
          // };

          const isUser = computed(() => {
               if (useAuthStore.user != null) {
                    isNonDoc.value = _.find(
                         useAuthStore.user?.kyc,
                         function (params) {
                              return (
                                   params?.type?.toLowerCase() == "usa non-doc"
                              );
                         }
                    );
               }
               return useAuthStore.user;
          });

          const kycStatus = computed(() => {
               return useAuthStore.kycStatus;
          });

          const closeModal = () => {
               isOpenIdVerification.value = false;
               useUtilitiesStore.enableScroll();
               router.replace({});
               // router.replace({ query: { modal: 'myaccount' } });
               // eventBus.emit("open:myaccount");
          };

          function launchWebSdk() {
               //    accessToken,
               //    applicantEmail,
               //    applicantPhone,
               //    customI18nMessages
               let snsWebSdkInstance = snsWebSdk
                    .init(
                         accessToken.value,
                         // token update callback, must return Promise
                         // Access token expired
                         // get a new one and pass it to the callback to re-initiate the WebSDK
                         () => getNewAccessToken()
                    )
                    .withConf({
                         lang: "en", //language of WebSDK texts and comments (ISO 639-1 format)
                         email: isUser.value["email"],
                         phone: isUser.value["phone"]
                    })
                    .withOptions({
                         addViewportTag: false,
                         adaptIframeHeight: true
                    })
                    // see below what kind of messages WebSDK generates
                    .on("idCheck.onStepCompleted", () => {
                         //  console.log("onStepCompleted", payload);
                    })
                    // see below what kind of messages WebSDK generates
                    .on("idCheck.onApplicantLoaded", async (payload) => {
                         applicantId.value = payload.applicantId;
                         const status = await checkStatusAppID(
                              applicantId.value
                         );
                         if (status) {
                              await useAuthStore.getUser(true);
                              // console.log(isUser.value);

                              isOpenIdVerification.value = false;
                              useUtilitiesStore.enableScroll();

                              router.push({ path: "/gold-coin-shop" });
                         }
                         //  console.log("onApplicantLoaded", payload);
                    })
                    // see below what kind of messages WebSDK generates
                    .on("idCheck.onStepInitiated", () => {
                         //  console.log("onStepInitiated", payload);
                    })
                    // see below what kind of messages WebSDK generates
                    .on("idCheck.onApplicantSubmitted", async () => {
                         await sendApplicantID();
                         //  console.log("onApplicantSubmitted", payload);
                    })
                    // see below what kind of messages WebSDK generates
                    .on("idCheck.onApplicantStatusChanged", (payload) => {
                         console.log(payload);
                         if (payload.reviewStatus == "completed") {
                              isComplete.value = true;
                              //      isOpenIdVerification.value = false;
                              //      // router.replace({ query: { modal: 'myaccount' } });
                              //    eventBus.off("open:idverification");
                              //    router.push({ path: "/" });
                         }
                         //  console.log("onApplicantStatusChanged", payload);
                    })
                    .on("idCheck.onError", (error) => {
                         // console.log('idCheck.onError');
                         console.log("onError", error);
                    })
                    .build();

               // you are ready to go:
               // just launch the WebSDK by providing the container element for it
               snsWebSdkInstance.launch("#sumsub-websdk-container");
          }

          async function getNewAccessToken() {
               return await getKYCAPI().then((res) => {
                    accessToken.value = res.token;
                    return res.token;
               });
          }

          async function sendApplicantID() {
               return await setApplicantIDAPI({
                    applicantId: applicantId.value
               }).then(() => {
                    successToast("Application already submitted!", "top-right");
               });
          }

          async function checkStatusAppID(id) {
               return await kycStatusAPI({
                    applicant_id: id
               })
                    .then((res) => {
                         return res?.status;
                    })
                    .catch(() => {
                         return false;
                    });
          }

          const back2Home = () => {
               window.location.href = "/lobby";
          };

          onMounted(async () => {
               await getNewAccessToken();
               eventBus.on("open:idverification", () => {
                    console.log("modal trigger");

                    useUtilitiesStore.disableScroll();
                    isOpenIdVerification.value = true;
                    // setTimeout(() => {
                    //      launchWebSdk();
                    // }, 1200);
               });
          });

          onUnmounted(() => {
               eventBus.off("open:idverification");
          });

          watch(accessToken, async (newVal) => {
               if (newVal && isOpenIdVerification.value) {
                    launchWebSdk();
               }
          });

          watch(isOpenIdVerification, async (newVal) => {
               if (newVal) {
                    const interval = setInterval(() => {
                         const el = document.getElementById(
                              "sumsub-websdk-container"
                         );

                         if (el) {
                              launchWebSdk();
                              clearInterval(interval);
                         }
                    }, 1000);
               }
          });

          return {
               isOpenIdVerification,
               closeModal,
               launchWebSdk,
               isComplete,
               back2Home,
               baseUrl,
               isNonDoc,
               kycStatus
          };
     }
};
</script>
